.bluehighlight {
	border-radius: 4px;
  }

  
  /* The animation starts here */
  .bluehighlight {
	position: relative;
  }
  
  .bluehighlight:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 4px;
	background: linear-gradient(120deg, #b6eaff, #385dff, #9f56ff);
	background-size: 300% 300%;
	clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
  }
  
  .bluehighlight.in:after {
	animation: frame-enter 1s forwards ease-in-out reverse, gradient-animation 4s ease-in-out infinite;
  }



  .greenhighlight {
	border-radius: 4px;
  }

  
  /* The animation starts here */
  .greenhighlight {
	position: relative;
  }
  
  .greenhighlight:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 4px;
	/*background: linear-gradient(120deg, #dcffe7, #227e4b, #116863);*/
	background: linear-gradient(120deg, #adff94, #4bac76, #11683c);
	background-size: 300% 300%;
	clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
  }
  
  .greenhighlight.in:after {
	animation: frame-enter 1s forwards ease-in-out reverse, gradient-animation 4s ease-in-out infinite;
  }



  
  /* motion */
  @keyframes gradient-animation {
	0% {
	  background-position: 15% 0%;
	}
	50% {
	  background-position: 85% 100%;
	}
	100% {
	  background-position: 15% 0%;
	}
  }
  
  @keyframes frame-enter {
	0% {
	  clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
	}
	25% {
	  clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) 100%, 100% 100%, 100% 0%, 0% 0%);
	}
	50% {
	  clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, 100% 0%, 0% 0%);
	}
	75% {
	  -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 0%, 0% 0%);
	}
	100% {
	  -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 0% 100%);
	}
  }